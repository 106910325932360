import $ from 'jquery'
import _ from 'lodash'
import 'lazysizes'

// Header Hamburger click handler
$('#header .hamburger').click(function () {
  // toggle hamburger animation
  $(this).toggleClass('is-active')

  // toggle mobile menu
  $('#mobile-menu-wrapper').toggleClass('is-active');
});

// add plus buttons for toggle mobile sub menus
$('#mobile-menu-wrapper .mobile-menu li.menu-item-has-children')
  .append('<button class="plus" aria-hidden><img src="/wp-content/themes/geld/assets/img/plus.png"/></button>');
  // TODO: quick fix  in staging
// on click handler for plus buttons
$('#mobile-menu-wrapper .mobile-menu li.menu-item-has-children button.plus').click(function() {
  $(this).toggleClass('open');
  $(this).siblings('ul.sub-menu').toggleClass('open');
})


// On header menu item hover handler
$('#header .menu-item-has-children').hover(function () {
  $(this).toggleClass('show');
  $('#header').toggleClass('show');
});

// On Filter button click handler
$('button.filter').click(function() {
  $('#subheader .filter-row').toggleClass('active');
  $('button.filter').toggleClass('active');
  $('#subheader').toggleClass('active');
});

// Activate Cookie Notice
$("li.menu-item a:contains('Cookie')").addClass('sp-dsgvo-show-privacy-popup');
$("li.menu-item a:contains('cookie')").addClass('sp-dsgvo-show-privacy-popup');

// Do not show Datenschutz h1 heading of DSGVO plugin
$(".the-content h1:contains('Datenschutz')").remove();
$(".the-content h1:contains('datenschutz')").remove();